// import React, { useState } from 'react';

// import data from './data';
// import Echolist from './Echolist';

// import { Grid } from '@material-ui/core';

// function Echosystem() {
//   const [projects, setprojects] = useState(data);
//   return (
//     <Grid container spacing={2}>
//       <Echolist projects={projects} />;
//     </Grid>
//   );
// }

// export default Echosystem;

//Default
import React from 'react';

//Group
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';
import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';
import ComingSoon from '../../assets/img/coming-soon.png';
import { Box, Typography } from '@mui/material';

function Echosystem() {
  return (
    <>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <Box sx={{display: 'flex', height: 'calc(100vh - 240px)', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{color: '#ffffff', fontSize: '24px'}}>Comming soon</Typography>
          </Box>
          
        </GridItem>
      </GridContainer>
    </>
  );
}

export default Echosystem;
