import { StandardTokenABI } from './ABI/StandardToken/Token.js';
import { TokenLockABI } from './ABI/TokenLock/tokenlock.js';
import { LptokenLockABI } from './ABI/LiquidityLock/lptokenlocker.js';
import { LptokenABI } from './ABI/LiquidityLock/lptoken.js';
import { PresaleManageABI } from './ABI/Presale/PresaleManage.js';
import { PresaleSettingABI } from './ABI/Presale/PresaleSetting.js';
import { PresaleABI } from './ABI/Presale/Presale.js';
// import {PresaleABI} from "./ABI/Presale/Presale_Temp.js";
import { LockForwarderABI } from './ABI/Presale/PresaleLockForwarder.js';
import { manageCreateTokenABI } from './ABI/CreateToken/CreateManage.js';
import { standardTokenCreateABI } from './ABI/CreateToken/StandardToken.js';
import { liquidityTokenCreateABI } from './ABI/CreateToken/LiquidityToken.js';

export const TOKENLOCK_ADDRESS = { 
  AlltraMainnet: '0x7B027Cf8Af16378Ea5E50934B25f2168f89b18A3',
};

export const LIQUIDITYLOCK_ADDRESS = {
  AlltraMainnet: '0xe79aABa5ce7fE7216FF2ab4D60CE323314ff49f4',
};

export const PRESALE_MANAGE_ADDRESS = {
  AlltraMainnet: '0xB318813f3A5EA2537d3518C0B8566751e62B26a5',
};

export const CREATE_TOKEN_MANAGE_ADDRESS = {
  AlltraMainnet: '0x78e81e9e21C71bcC89c59b8cFBFC87BFA96aAf07',
};

////////////////////////////////////////////////////////////////////////////////////////////

export const STANDARD_TOKEN_ABI = StandardTokenABI;

export const TOKEN_LOCK_ABI = TokenLockABI;

export const LIQUIDITY_LOCK_ABI = {
  lock: LptokenLockABI,
  token: LptokenABI,
};

export const PRESALE_ABI = {
  manage: PresaleManageABI,
  setting: PresaleSettingABI,
  presale: PresaleABI,
  lockforwarder: LockForwarderABI,
};

export const CREATE_TOKEN_ABI = {
  manage: manageCreateTokenABI,
  standard: standardTokenCreateABI,
  liquidity: liquidityTokenCreateABI,
};

export const GetCoinPriceURL = {
  AlltraMainnet: 'https://min-api.cryptocompare.com/data/price?fsym=ALL&tsyms=BTC,USD,EUR',
};

export const CRYPTCOMPARE_API_KEY = '01d755f049bd018d51482b53a10de224f472386278d6e5951d7191eaac699d48';
