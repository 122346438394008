//Default
import React from "react";

//Group
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import Button from "@mui/material/Button";

function Home() {
  return (
    <div className="w-full max-w-[1370px] lg:px-[160px] px-[15px] font-['Russo_One']">
      <h1 className="text-[64px] text-center font-['Russo_One'] mb-[45px]">DASHBOARD</h1>
      <p className="text-2xl text-[#44F744] mb-4">Welcome to ALLTRA Smartchain Launchpad</p>
      <p className="text-5xl text-white mb-4">Secure DeFi Infrastructure</p>
      <p className="text-2xl text-[#44F744] mb-[50px]">Decentralized, Scalable & trusted powered by ALLTRA</p>
      <p className="text-2xl text-white mb-4">
        The ALLTRA Smartchain Launchpad helps users create their own tokens and token sales in a few short minutes
        within the following networks:
      </p>
      <p className="text-2xl text-white mb-[50px]">ALLTRA Smartchain</p>
      <p className="text-4xl text-white mb-4">Welcome to ALLTRA World Smartchain Launchpad</p>
      <a
        href="https://www.alltraverse.com/express-checkout"
        className="bg-[#006600] text-white rounded-[5px] px-[17px] py-1 uppercase text-2xl"
        target="_blank"
        rel="noopener noreferrer"
      >
        Use card to Buy ALL
      </a>
    </div>
  );
}

export default Home;
