//Default
import React from 'react';

//Group
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';

import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useWeb3React } from '@web3-react/core';

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    color: '#868DA6',
    fontSize: 18,
  },
  body: {
    fontSize: 18,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

function createData(name, address, link) {
  return { name, address, link };
}

const rows = {
  AlltraMainnet : [
    createData(
      'Token Factory CreateManage: ',
      '0x78e81e9e21C71bcC89c59b8cFBFC87BFA96aAf07',

      <a target={'_blank'} href="https://alltra.global/address/0x78e81e9e21C71bcC89c59b8cFBFC87BFA96aAf07">
        {' '}
        Explore
      </a>
    ),
    createData(
      'Token Lock: ',
      '0x7B027Cf8Af16378Ea5E50934B25f2168f89b18A3',
      <a target={'_blank'} href="https://alltra.global/address/0x7B027Cf8Af16378Ea5E50934B25f2168f89b18A3">
        {' '}
        Explore
      </a>
    ),
    createData(
      'Liquidity Lock: ',
      '0xe79aABa5ce7fE7216FF2ab4D60CE323314ff49f4',
      <a target={'_blank'} href="https://alltra.global/address/0xe79aABa5ce7fE7216FF2ab4D60CE323314ff49f4">
        {' '}
        Explore
      </a>
    ),
    createData(
      'Presale Manage: ',
      '0xB318813f3A5EA2537d3518C0B8566751e62B26a5',
      <a target={'_blank'} href="https://alltra.global/address/0xB318813f3A5EA2537d3518C0B8566751e62B26a5">
        {' '}
        Explore
      </a>
    ),
    createData(
      'Lending PoolFactory: ',
      '0x73E1b2a7aa473868D754A0DE008dc00dC7cC1aD1',
      <a target={'_blank'} href="https://alltra.global/address/0x73E1b2a7aa473868D754A0DE008dc00dC7cC1aD1">
        {' '}
        Explore
      </a>
    ),
    createData(
      'Staking PoolFactory: ',
      '0x08cbb6e692f8acb40c7318b021128b669510850d',
      <a target={'_blank'} href="https://alltra.global/address/0x08cbb6e692f8acb40c7318b021128b669510850d">
        {' '}
        Explore
      </a>
    ),
    createData(
      'MSwap Factory:  ',
      '0x86838E65E45B9F3c0b01d3c8A9F787f80814ef10',
      <a target={'_blank'} href="https://alltra.global/address/0x86838E65E45B9F3c0b01d3c8A9F787f80814ef10">
        {' '}
        Explore
      </a>
    ),
  ]
};



function Contracts(props) {
  const { library } = useWeb3React();
  if (!library) {
    return;
  }

  if (!library._network) {
    return;
  }
  let contractCurr = rows.AlltraMainnet;

  if (library._network.chainId === 651940) {
    contractCurr = rows.AlltraMainnet;
  }

  return (
    <>
      {/* <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardBody></CardBody>
          </Card>
        </GridItem>
      </GridContainer> */}

      <TableContainer component={Paper} sx={{backgroundColor: 'transparent'}}>
        <Table sx={{ borderSpacing: '0 5px', borderCollapse: 'initial' }} aria-label="simple table">
          <TableHead>
            <TableRow 
              sx={{ 
                '& th': {border: 'none', color: '#868DA6', paddingBottom: '10px', fontSize: '14px'}, 
                '& th:first-child': {paddingLeft: '26px'},
                '& th:last-child': {paddingRight: '26px'}
              }}
            >
              <TableCell>CONTRACT NAME</TableCell>
              <TableCell>ADDRESS</TableCell>
              <TableCell>EXPLORE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { contractCurr.map((row, index) => (
              <TableRow
                sx={{ 
                  '& td': {border: 'none', background: 'none', color: '#ffffff', paddingTop: '18px', paddingBottom: '18px', fontSize: '16px'}, 
                  '& td:first-child': {borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px', paddingLeft: '26px'},
                  '& td:last-child': {borderTopRightRadius: '6px', borderBottomRightRadius: '6px', paddingRight: '26px'}
                }}
                key={index}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.link}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Contract Name</CustomTableCell>
              <CustomTableCell align="Left">
                <b>Address</b>
              </CustomTableCell>
              <CustomTableCell align="Left">Explore</CustomTableCell>
              <CustomTableCell align="right">Fat (g)</CustomTableCell>
              <CustomTableCell align="right">Carbs (g)</CustomTableCell>
              <CustomTableCell align="right">Protein (g)</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow className={classes.row} key={row.id}>
                <CustomTableCell width={300} component="th" scope="row">
                  <b> {row.name}</b>
                </CustomTableCell>
                <CustomTableCell width={600} align="left">
                  <b>{row.address}</b>
                </CustomTableCell>
                <CustomTableCell align="left">
                  <b style={{ color: 'pink' }}>{row.link}</b>
                </CustomTableCell>
                <CustomTableCell align="right">{row.fat}</CustomTableCell>
                <CustomTableCell align="right">{row.carbs}</CustomTableCell>
                <CustomTableCell align="right">{row.protein}</CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper> */}
    </>
  );
}

Contracts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contracts);
