/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";
import Logo from "../../assets/img/logo.png";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className="container pb-6">
      <div className="flex justify-center mb-2">
        <img src={Logo} className="size-5" />
      </div>
      <p className="text-[12px] font-[Manrope] font-extralight leading-[10px] text-center">
        All rights reserved OZZ Metals Co Pty Ltd
      </p>
      <p className="text-[12px] font-[Manrope] font-extralight leading-[10px] text-center">Copyright 2024 Creator Nathan Anema</p>
    </footer>
  );
}
